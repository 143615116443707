export const LANGUAGES = {
    ESPANOL: 'es',
    INGLES: 'en'
}

export const URLS_PORFOLIO = [];
URLS_PORFOLIO.push({
        title: 'Behance',
        icon: 'fa-brands fa-behance',
        url: 'https://www.behance.net/imperioame'
    }, {
        title: 'Instagram',
        icon: 'fa-brands fa-instagram',
        url: 'https://www.instagram.com/imperioame/'
    }, {
        title: 'Flickr',
        icon: 'fa-brands fa-flickr',
        url: 'https://flickr.com/photos/imperioame/'
    }, {
        title: 'Sketchfab',
        icon: 'fa-brands fa-unity',
        url: 'https://sketchfab.com/imperioame'
    }

);

export const CATEGORIAS_PORFOLIO = {
    DISENO: 1,
    PUBLICITARIO: 2,
    D3: 3,
    FOTOGRAFIA: 4,
    WEBJUEGOS: 5
}


export const TRABAJOS_PORFOLIO = [];
TRABAJOS_PORFOLIO.push({
    id: 1,
    title: 'VoRges',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/095573160433619.Y3JvcCwxMzUwLDEwNTUsMCwxMw.jpg',
    link: 'https://www.behance.net/gallery/160433619/VoRges-VR-app-of-a-JL-Borgess-short-tale',
    priority: 1,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.WEBJUEGOS
}, {
    id: 2,
    title: 'BitBang fest',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/3cd542150708753.Y3JvcCw0MTY4LDMyNjAsMCw0NTM.png',
    link: 'https://www.behance.net/gallery/150708753/BitBang-2020-Desarrollo-de-identidad-visual-y-Campana',
    priority: 1,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.PUBLICITARIO
}, {
    id: 3,
    title: 'Godspeed',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/d38c39152608337.Y3JvcCwyNzI3LDIxMzMsMCwyNw.jpg',
    link: 'https://www.behance.net/gallery/152608337/Godspeed-3D-Gods-creation',
    priority: 2,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.D3
}, {
    id: 4,
    title: 'Domingo Granja',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/c9fc1d156358269.Y3JvcCwyMDMwLDE1ODcsMCwyMA.jpg',
    link: 'https://www.behance.net/gallery/156358269/Domingo-Granja-Branding-y-Web',
    priority: 1,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.DISENO
}, {
    id: 5,
    title: "Miko's Story",
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/cb9ab9155963781.Y3JvcCwxODE4LDE0MjIsMCwxOA.jpg',
    link: 'https://www.behance.net/gallery/155963781/Mikos-Story-An-AI-made-graphic-novel',
    priority: 2,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.DISENO
}, {
    id: 6,
    title: "Uptown",
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/9fb975159547247.Y3JvcCwzODY4LDMwMjUsMCwzOA.jpg',
    link: 'https://www.behance.net/gallery/159547247/Uptown-Photography-Session-by-Angie-Perfumo',
    priority: 3,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.FOTOGRAFIA
}, {
    id: 7,
    title: 'BMW S1000XR',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/9cddcc149302767.Y3JvcCwxMjc4LDEwMDAsNTMsMA.jpg',
    link: 'https://www.behance.net/gallery/149302767/BMW-S1000-XR-por-Virginia-Guidetti',
    priority: 1,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.FOTOGRAFIA
}, {
    id: 8,
    title: 'Manual de Marca - Voluntarias del Hospital Gutierrez',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/ebe072152780691.Y3JvcCw1NjgxLDQ0NDMsMCw1Ng.jpg',
    link: 'https://www.behance.net/gallery/152780691/Manual-de-Marca-Voluntarias-del-Hospital-Gutierrez',
    priority: 2,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.DISENO
}, {
    id: 9,
    title: 'Impervious',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/b5e635156654979.Y3JvcCw5OTksNzgyLDAsOQ.jpg',
    link: 'https://www.behance.net/gallery/156654979/Impervious-3D-Intervention-over-photography',
    priority: 2,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.D3
}, {
    id: 10,
    title: 'Sentimientos Encontrados',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/112162147953565.Y3JvcCw4MDgsNjMyLDAsMzQ.png',
    link: 'https://www.behance.net/gallery/147953565/Sentimientos-encontrados-Serie-fotografica-en-5000km',
    priority: 1,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.FOTOGRAFIA
}, {
    id: 11,
    title: 'Volvo - Photography Session by Volvo Arg.',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/a1ca53163258029.Y3JvcCwzNTE3LDI3NTAsMCwzNA.jpg',
    link: 'https://www.behance.net/gallery/163258029/Volvo-Photography-Session-by-Volvo-Arg',
    priority: 3,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.FOTOGRAFIA
}, {
    id: 12,
    title: 'Costa y Roca - Wine Imagery',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/808/0d7da6153975233.Y3JvcCwxMjMxLDk2MywzNDQsNTc.png',
    link: 'https://www.behance.net/gallery/153975233/Costa-y-Roca-Wine-Imagery',
    priority: 1,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.FOTOGRAFIA
}, {
    id: 13,
    title: 'Casamiento - Colombres & Gomez Pizarro / Sesión Fotogr.',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/6e6697149847251.Y3JvcCw1MDAwLDM5MTAsMCw0OQ.jpg',
    link: 'https://www.behance.net/gallery/149847251/Casamiento-Colombres-Gomez-Pizarro-Sesion-Fotogr',
    priority: 2,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.FOTOGRAFIA
}, {
    id: 14,
    title: 'Meteor 350 por Virginia Guidetti',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/efa3fd149649961.Y3JvcCwxNTAwLDExNzMsMCwxNA.jpg',
    link: 'https://www.behance.net/gallery/149649961/Meteor-350-por-Virginia-Guidetti',
    priority: 2,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.FOTOGRAFIA
}, {
    id: 15,
    title: 'World Sighting - 3d world building experimentation',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/04b042151586163.6314b9ddd768e.png',
    link: 'https://www.behance.net/gallery/151586163/World-Sighting-3d-world-building-experimentation',
    priority: 2,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.D3
}, {
    id: 16,
    title: 'ReFaced - 3D interventions over portraits',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/e7c85d150592763.Y3JvcCwxMjAwLDkzOCwwLDEzMA.jpg',
    link: 'https://www.behance.net/gallery/150592763/ReFaced-3D-interventions-over-portraits',
    priority: 1,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.D3
}, {
    id: 17,
    title: 'FRUITHEADS - 3D trip',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/519a9b159129571.Y3JvcCwxMjAwLDkzOCwwLDEx.jpg',
    link: 'https://www.behance.net/gallery/159129571/FRUITHEADS-3D-trip',
    priority: 2,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.D3
}, {
    id: 18,
    title: 'Potrero Digital - Campaña e Identidad de Marca',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/f37188140036363.Y3JvcCw4MDgsNjMyLDAsMA.png',
    link: 'https://www.behance.net/gallery/140036363/Potrero-Digital-Campana-e-Identidad-de-Marca',
    priority: 2,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.PUBLICITARIO
}, {
    id: 19,
    title: 'Campaña Publicitaria Netflix - Temporada de Reciclaje',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/a7ce8b157219767.Y3JvcCw5OTksNzgyLDAsOQ.jpg',
    link: 'https://www.behance.net/gallery/157219767/Campana-Publicitaria-Netflix-Temporada-de-Reciclaje',
    priority: 2,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.PUBLICITARIO
}, {
    id: 20,
    title: 'Costa y Roca - Wine Label Design',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/6cf484139617309.Y3JvcCwyNzI3LDIxMzMsMCwyNw.jpg',
    link: 'https://www.behance.net/gallery/139617309/Costa-y-Roca-Wine-Label-Design',
    priority: 2,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.DISENO
}, {
    id: 21,
    title: 'Prime - El juego más seguro (Videojuego)',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/cb2203139619709.Y3JvcCwxMzgwLDEwODAsMjMyLDA.png',
    link: 'https://www.behance.net/gallery/139619709/Prime-El-juego-mas-seguro-%28Videojuego%29',
    priority: 2,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.WEBJUEGOS
}, {
    id: 22,
    title: 'RelaxApp - Research y proceso iterativo UX/UI',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/60e1e6108371457.Y3JvcCw0MjEwLDMyOTIsMCww.png',
    link: 'https://www.behance.net/gallery/108371457/RelaxApp-Research-y-proceso-iterativo-UXUI',
    priority: 1,
    source: 'behance',
    category: CATEGORIAS_PORFOLIO.WEBJUEGOS
}, {
    id: 23,
    title: 'MoroArte-GranKan',
    img: 'https://repository-images.githubusercontent.com/531989722/b0845be0-56d2-42e2-a264-a4ba2313c98e',
    link: 'https://imperioame.github.io/MoroArte-GranKan/',
    priority: 2,
    source: 'github',
    category: CATEGORIAS_PORFOLIO.WEBJUEGOS
}, {
    id: 24,
    title: 'Screen Colour Engine',
    img: 'https://repository-images.githubusercontent.com/309862473/f320395e-9fb8-4792-a2a2-fcbe81e312c0',
    link: 'https://imperioame.github.io/ScreenColourEngine/',
    priority: 1,
    source: 'github',
    category: CATEGORIAS_PORFOLIO.WEBJUEGOS,
    extra_css: 'background-color: black; padding: 3em 0'
}, {
    id: 25,
    title: 'Vecindad',
    img: 'https://repository-images.githubusercontent.com/199515880/3213bff2-7e98-4ae1-86ef-5892675ab247',
    link: 'https://imperioame.github.io/vecindad/',
    priority: 2,
    source: 'github',
    category: CATEGORIAS_PORFOLIO.WEBJUEGOS
}, {
    id: 26,
    title: 'Bar assetpack',
    img: 'https://media.sketchfab.com/models/ca27711bd71946a49413e0b743e51572/thumbnails/5eea19ac82cc4636b0bd3ac31316ce39/e97afa4b4e6348e39d3ca6191147757d.jpeg',
    link: 'https://sketchfab.com/3d-models/bar-pub-asset-pack-with-props-for-games-ca27711bd71946a49413e0b743e51572',
    priority: 2,
    source: 'Sketchfab',
    category: CATEGORIAS_PORFOLIO.D3
}, {
    id: 27,
    title: 'Seba Gallardo - Photo Session',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/7725ba164262227.Y3JvcCwzOTM4LDMwODAsMCwzOA.jpg',
    link: 'https://www.behance.net/gallery/164262227/Fiverr-content-Photo-Session-for-a-Freelancer',
    priority: 2,
    source: 'Behance',
    category: CATEGORIAS_PORFOLIO.FOTOGRAFIA
}, {
    id: 28,
    title: 'Sitio Web Agencia Álterego',
    img: 'https://repository-images.githubusercontent.com/320118591/54173200-3d01-11eb-9e3f-c283bb81ad89',
    link: 'https://agenciaalterego.com.ar/',
    priority: 2,
    source: 'Web',
    category: CATEGORIAS_PORFOLIO.WEBJUEGOS,
    extra_css: 'background-color: white; padding: 3em 0'
}, {
    id: 29,
    title: 'Staley Martial Arts',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/c85b13139617007.Y3JvcCw4MDgsNjMyLDAsMA.png',
    link: 'https://www.staleymartialarts.com/',
    priority: 3,
    source: 'Web',
    category: CATEGORIAS_PORFOLIO.WEBJUEGOS
}, {
    id: 30,
    title: 'Box Office - A mindful office full of productivity',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/max_808_webp/532aee165767599.Y3JvcCwxMjAwLDkzOCwwLDEx.jpg',
    link: 'https://www.behance.net/gallery/165767599/Box-Office-A-mindful-office-full-of-productivity?',
    priority: 2,
    source: 'Behance',
    category: CATEGORIAS_PORFOLIO.D3
}, {
    id: 31,
    title: 'Early Works: Books covers & Flyers',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/808/55a8c5190355147.Y3JvcCw2MDAwLDQ2OTMsMCwyOTc.jpg',
    link: 'https://www.behance.net/gallery/190355147/Early-Works-Books-covers-Flyers',
    priority: 2,
    source: 'Behance',
    category: CATEGORIAS_PORFOLIO.DISENO
}, {
    id: 32,
    title: 'Early Works: Banners, signs and Clothing',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/808/fdd1a6190383341.Y3JvcCw5OTksNzgyLDAsMTQ.jpg',
    link: 'https://www.behance.net/gallery/190383341/Early-Works-Banners-signs-and-Clothing',
    priority: 2,
    source: 'Behance',
    category: CATEGORIAS_PORFOLIO.DISENO
}, {
    id: 33,
    title: 'Beer can design - Spika Beer Company',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/808/a14997175796205.Y3JvcCwxNTc1LDEyMzEsMCwyMw.jpg',
    link: 'https://www.behance.net/gallery/175796205/Beer-can-design-Spika-Beer-Company',
    priority: 2,
    source: 'Behance',
    category: CATEGORIAS_PORFOLIO.DISENO
}, {
    id: 34,
    title: 'Ruido - A design experience full of noise',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/404/89714a170483673.Y3JvcCw5OTksNzgyLDAsOQ.jpg',
    link: 'https://www.behance.net/gallery/170483673/Ruido-A-design-experience-full-of-noise',
    priority: 1,
    source: 'Behance',
    category: CATEGORIAS_PORFOLIO.DISENO
}, {
    id: 35,
    title: 'Julie - Photographic Sessión at night',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/808/850919171257147.Y3JvcCw0Nzc2LDM3MzUsMCw0Nw.jpg',
    link: 'https://www.behance.net/gallery/171257147/Julie-Photographic-Session-at-night',
    priority: 2,
    source: 'Behance',
    category: CATEGORIAS_PORFOLIO.FOTOGRAFIA
}, {
    id: 36,
    title: '2023 World Photography Day photo walk in Buenos Aires',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/808/c535eb179057151.Y3JvcCw0OTg4LDM5MDEsMCw3NA.jpg',
    link: 'https://www.behance.net/gallery/179057151/2023-World-Photography-Day-photo-walk-in-Buenos-Aires',
    priority: 1,
    source: 'Behance',
    category: CATEGORIAS_PORFOLIO.FOTOGRAFIA
}, {
    id: 37,
    title: 'Elal y Nosjteh - Tribal Axe Craft + Photo session',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/808/82d8e3179421987.Y3JvcCw1ODQwLDQ1NjcsMCw1Nw.jpg',
    link: 'https://www.behance.net/gallery/179421987/Elal-y-Nosjteh-Tribal-Axe-Craft-Photo-session',
    priority: 1,
    source: 'Behance',
    category: CATEGORIAS_PORFOLIO.FOTOGRAFIA
}, {
    id: 38,
    title: 'Anael - Photo Session by Nicolás Ochoa',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/808/1e3147181940685.Y3JvcCwzNDA4LDI2NjYsMCww.jpg',
    link: 'https://www.behance.net/gallery/181940685/Anael-Photo-Session-by-Nicolas-Ochoa',
    priority: 2,
    source: 'Behance',
    category: CATEGORIAS_PORFOLIO.FOTOGRAFIA
}, {
    id: 39,
    title: 'Little Sina in Auckland City - Photographic Session',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/808/03ec40185616447.Y3JvcCwzOTk5LDMxMjgsMCwxMDE5.jpg',
    link: 'https://www.behance.net/gallery/185616447/Little-Sina-in-Auckland-City-Photographic-Session',
    priority: 2,
    source: 'Behance',
    category: CATEGORIAS_PORFOLIO.FOTOGRAFIA
}, {
    id: 40,
    title: '3D - Weekly cretive pieces in Blender',
    img: 'https://mir-s3-cdn-cf.behance.net/projects/808/b74896178951577.Y3JvcCw4MTEsNjM1LDMyNyww.jpg',
    link: 'https://www.behance.net/gallery/178951577/3D-Weekly-cretive-pieces-in-Blender',
    priority: 1,
    source: 'Behance',
    category: CATEGORIAS_PORFOLIO.D3
}, {
    id: 41,
    title: 'Antrieb Sudamericana Web',
    img: 'https://i0.wp.com/antriebsa.com/wp-content/uploads/2018/10/cropped-logo-antrieb-01.png',
    link: 'https://antriebsa.com/',
    priority: 1,
    source: 'Web',
    category: CATEGORIAS_PORFOLIO.WEBJUEGOS,
    extra_css: 'background-color: white; padding: 10vh 0; box-sizing: border-box'
}, {
    id: 42,
    title: 'Imperio Usados Web',
    img: 'https://http2.mlstatic.com/storage/mshops-appearance-api/images/70/66709070/logo-2019050418345411800.png',
    link: 'https://www.imperio-usados.com.ar/',
    priority: 2,
    source: 'Web',
    category: CATEGORIAS_PORFOLIO.WEBJUEGOS,
    extra_css: 'background-color: white; padding: 0 6em; box-sizing: border-box;'
}, {
    id: 43,
    title: 'Landrú.org Web design and Coordination',
    img: 'https://www.landru.org/wp-content/uploads/2021/12/11_gato.svg',
    link: 'https://www.landru.org/',
    priority: 2,
    source: 'Web',
    category: CATEGORIAS_PORFOLIO.WEBJUEGOS,
    extra_css: 'background-color: white; padding: 2em 0'
});


export const TEXTOS = {
    es: {},
    en: {}
}

TEXTOS.es = {
    TITULO_PAGINA: 'Julián Mario Amé | Diseñador Multimedial',
    SUBTITLE_HERO: 'Diseñador Multimedial | Lic. En Comunicación Digital e Interactiva<br>Director de proyectos digitales | Quality Assurance Lead',
    EXTRACTO_BANNER_MI_PERSONA: "Diseñador Multimedial y líder de proyectos digitales.<br> Emprendedor y apasionado en la organización y organizaciones.<br> Técnico por naturaleza.",
    BOTON_CV_BANNER_MI_PERSONA: 'Ver CV',
    CATEGORIAS_PORFOLIO: {
        DISENO: 'Diseño',
        PUBLICITARIO: 'Publicitario',
        D3: '3D',
        FOTOGRAFIA: 'Fotografía',
        WEBJUEGOS: 'Web & Juegos'
    },
    TITULO_CONTACTO: 'Contacto',
    BLOQUE_CONTACTO_TITULO: 'Escribime aquí 👇',
    BLOQUE_CONTACTO_EXTRA: {
        PLACEHOLDER_INPUT: "Si llegaste hasta acá, te propongo dos cosas:\n1: Contactarme en el próximo formulario\n2: Jugar con este campo, vinculado con GPT2. Escribí acá y tocá el botón 'Generar texto'",
        BOTON_GENERAR_TEXTO: "Generar texto",
        SMALL_INFO: "Aquí una pequeña integración con GPT2 de Hugging Face. Podes divertirte probando diferentes combinaciones. Intentá no romperla 😣",
        SMALL_PD: "PD: Está entrenado en Español. Para mejores resultados en inglés podes cambiar el lenguaje desde el ícono arriba a la derecha de la pantalla ☝"
    },
    CAMPOS_FORMULARIO: {
        NOMBRE: 'Nombre',
        NOMBRE_PLACEHOLDER: 'Juan',
        EMAIL: 'Email',
        EMAIL_PLACEHOLDER: 'juan@correo.com.ar',
        MENSAJE: 'Mensaje',
        MENSAJE_PLACEHOLDER: 'Me gustó mucho tu página web...',
        BOTON_ENVIAR: 'Enviar'
    },
    FOOTER: 'Desarrollado por '

}

TEXTOS.en = {
    TITULO_PAGINA: 'Julián Mario Amé | Multimedia Designer',
    SUBTITLE_HERO: "Multimedia Designer | Degree in Digital and Interactive Communication<br>Digital Project Manager | Quality Assurance Lead",
    EXTRACTO_BANNER_MI_PERSONA: "Multimedia Designer and leader of digital projects.<br>Entrepreneur and passionate about organization and organizations.<br>Technical by nature.",
    BOTON_CV_BANNER_MI_PERSONA: 'See CV',
    CATEGORIAS_PORFOLIO: {
        DISENO: 'Design',
        PUBLICITARIO: 'Advertising',
        D3: '3D',
        FOTOGRAFIA: 'Photography',
        WEBJUEGOS: 'Web & Games'
    },
    TITULO_CONTACTO: 'Contact form',
    BLOQUE_CONTACTO_TITULO: 'Contact me here 👇',
    BLOQUE_CONTACTO_EXTRA: {
        PLACEHOLDER_INPUT: "If you've arrived here, I propose two things:\n1: Write me through the next form.\n2: Play with this field, linked to GPT2. Write here and click the button 'Generate text'",
        BOTON_GENERAR_TEXTO: "Generate text",
        SMALL_INFO: "Here is a small integration with GPT2 from Hugging Face. You can have fun trying different combinations. Try not to break it 😣",
        SMALL_PD: "PS: It only speaks English. You can change the language from the icon on the top right to the right of the screen ☝"
    },
    CAMPOS_FORMULARIO: {
        NOMBRE: 'Name',
        NOMBRE_PLACEHOLDER: 'Juan',
        EMAIL: 'Email',
        EMAIL_PLACEHOLDER: 'juan@mail.com',
        MENSAJE: 'Mesage',
        MENSAJE_PLACEHOLDER: 'I like your website a lot...',
        BOTON_ENVIAR: 'Send'
    },
    FOOTER: 'Developed by '
}